.header_admin {
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #ECE3CE, #71a46f);
    border-bottom: 4px solid #739072; 
    border-radius: 10px;
    margin-left: 0;
    flex-wrap: wrap;
    
  }
  
  .header_admin img {
    width: 150px; 
    margin-right: 20px;
  }
  
  .header_admin h1 {
    font-size: 24px;
    font-weight: bold;
  }
.transactions-table-container {
    width: 100%;
    height: 100vw;
    text-align: center;
    padding: 20px 0;
    background: #e4eace;
    color:#3a4d39;
    font-weight: 500;
  }
  .transactions-table-container h2 {
    @media (max-width:768px) {
      font-size: 20px;
    }
  }
  
  h2{
    font-size: xxx-large;
    font-family: 'Segoe UI', serif;
    color: #3a4d39;
  }
  .transactions-table-wrapper {
    background-color: #e4eace;
    max-width: max-content;
    margin: 20px auto;
    border: 5px solid #3a4d39;
    padding: 20px;
    overflow-x: auto;
  }
  
  .transactions-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }
  
  .transactions-table th, .transactions-table td {
    border: 1px solid #3a4d39 ;
    padding: 8px;
    text-align: center;
  }
  
  .transactions-table th {
    background-color: #ADBC9F;
  }
  
  .transactions-table tbody tr:hover {
    background-color: #dddd;
  }