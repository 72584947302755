.login {
    background: linear-gradient(109.6deg, rgb(251, 250, 225) 11.2%, rgb(206, 240, 185) 47.5%, rgb(100, 163, 111) 100.2%);
}

.container_login {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    margin-top: 100px;
    width: 75%;
    background: rgb(251, 250, 225);
    padding: 10px;
    padding-top:2%;
    border-radius: 5px;
    height:75%;
}

.left-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
    margin-right: 20px; 
    position: relative;
}

@media (min-width:992px) {
    .separator {
        position: absolute;
        height: 100%; 
        width: 2px;
        background-color: #3A4D39;
        top: 0;
        right: 0; 
    }
    .left-content {
        width: 45%;
    }
    .right-content {
        width: 55%;
    }
}
/* .separator {
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: #3A4D39;
    top: 0;
    right: 0;
} */

.login-image {
    margin: auto;
    max-width: 80%;
    height: auto;
    margin-bottom: 20px;
    background-color: transparent;
    border: none;
    
}
.qr_button{
    border: none;
    padding: 0;
    background:none;
    cursor: pointer;
}
.qrscanner{
    margin: auto;
    max-width: 85%;
    height: auto;
    margin-bottom: 20px;
    background-color: transparent;
    border: none;
}
.scanner{
padding-left: 15%;
height: fit-content;
width:auto;
}
.cancel_button{
    width: 220px;
    height: 59px;
    color: #3A4D39;
    background: #aeb0af;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
    border: none;
    outline: none;
     margin-top: 10px; /* Optional: Adjust the top margin as needed */  
}
.right-content {
    flex: 1;
    text-align: center;

}
.login .right-content {
    width: 100%;
}
.login .right-content .input{
    padding-right: 15px;
}
.login .right-content .input input {
  padding: 10px;
}


.header_login{
    margin-bottom: 30px;
    color: #3A4D39; 
    font-size: xx-large; 
    text-align: center; 
    padding: 0; 
    margin: 0; 
    border: none; 
    text-align: center;
}
.inputs {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    width: 100%;
}

.input {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 480px;
    height: 80px;
    background: #ADBC9F;
    border-radius: 6px;
}

.input img {
    margin: 0 15px;
}

.input input {
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #3A4D39;
    font-size: 19px;
}

.forgot-password {
    margin-top: 27px;
    color: #3A4D39;
    font-size: 18px;
}

.forgot-password span {
    cursor: pointer;
}

.submit-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.submit {
    width: 220px;
    height: 59px;
    color: #3A4D39;
    background: #ADBC9F;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
    border: none;
    outline: none;
}

.submit.gray {
    background: #eaeaea;
    color: #676767;
}

@media screen and (max-width: 992px) {
    .container_login {
        flex-direction: column;
        /* Adjusted flex-direction for mobile view */
        width: 100%;
    }

    .left-content,
    .right-content {
        align-items: center;
    }

    .input input {
        width: 80%;
    }
}