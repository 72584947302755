.forgotContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.forgotHeader {
    text-align: center;
    background-color: #cdd9a6;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    height: 60%;
}

.forgotLogo {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.forgotTitle {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: -10px;
}

.forgotHeader h2 {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
}

.forgetInput {
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgetInput img {
    width: 35px;
    height: 35px;
    margin-right: 5px;
}

.forgetEmail {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    flex-grow: 1;
    margin-right: 10px;
}

.forgotButton {
    padding: 10px 20px;
    background-color: #3A4D39;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.forgotButton:hover {
    background-color: #45a049;
}

.forgetInput p {
  position: absolute;
  bottom: 22%; 
  left: 50%; 
  transform: translateX(-50%); 
  }
  
  .forgetInput p a {
    color: darkmagenta; 
    text-decoration: none; 
  }
  .forgetInput p a:hover {
    text-decoration: underline;
  }
