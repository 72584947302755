.register{
    background: linear-gradient(109.6deg, rgb(251, 250, 225) 11.2%, rgb(206, 240, 185) 47.5%, rgb(100, 163, 111) 100.2%);
}
/* .register-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 100%;
    height: 100%;
    background: rgb(251, 250, 225);
    
}
.separator {
    position: absolute;
    height: 100%;
    width: 5px;
    background-color: #3A4D39;
    top: 0;
    right: 0;
} */
.header_register{
    text-align: center;
    margin-bottom: 20px; 
}

.register_left{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 10px;
    margin-right: 20px; 
    position: relative;
    width:45%;
}


@media (min-width:768px) {
    .reg-separator {
        position: absolute;
        height: 100%; 
        width: 2px;
        background-color: #3A4D39;
        top: 0;
        right: 0; 
    }
}

.reg_icon {
    margin: auto;
    max-width: 100%;
    height: 250px;
    margin-bottom: 20px;
    padding-right:0;
    padding-left:10%;
}
@media (min-width:768px) {
    .reg_icon {
        max-width: 130%;
        height: auto;
    }
}

.right-content {
    flex: 1; 
    text-align: center;
    width:55%;
}

.header_register {
    color: #3A4D39; 
    font-size: xx-large; 
    text-align: center; 
    padding: 0; 
    border: none; 
    text-align: center;
}

.text {
    color: #3A4D39;
    font-size: 48px;
    font-weight: 700;
}

.reg_input {
    display: flex;
    align-items: center;
    width: 80%;
    max-width: 400px;
    height: 60px;
    background: #ADBC9F;
    border-radius: 6px;
    margin-bottom: 2%;
    padding-left: 3%;
}

.reg_inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100%;
    
}

.reg_input input {
    flex: 1;
    width: 90%; 
    max-width: 400px;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    color: #3A4D39;
    font-size: 19px;
    padding: 10px;
}
.input img {
    margin-right: 15px; 
}

.forgot-password {
    margin-top: 20px; 
    color: #3A4D39;
    font-size: 18px;
}

.submit-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.register .outer-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;

}
.register .inner-wrapper .right-content {
    width: 70%;
}

.register .inner-wrapper .register_left  {
    width: 30%;
}
@media (max-width:767px) {
    .register .inner-wrapper .register_left {
        width: 100%;
    }
    .register .inner-wrapper .right-content {
        width: 100%;
    }
}



.register .inner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
    background: rgb(251, 250, 225);
    padding:10px;
    border-radius: 5px;
    max-width: 600px;
}
.submit {
    width: 220px;
    height: 59px;
    color: #3A4D39;
    background: #ADBC9F;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
    border: none;
    outline: none;
}

.submit.gray {
    background: #eaeaea;
    color: #676767;
}


@media screen and (max-width: 768px) {
    .register-container {
        width: auto;
        height: auto; 
    }
    
    .left-content, .right-content {
        flex-direction: column;
        align-items: center;
    }
    
    .reg_input {
        width: 90%;
    }
}
